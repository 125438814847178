/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
//Ferramenta estilo hotjar da microsoft Clarity

export default class Clarity {
  static isInited = false;

  constructor(code: string) {
    if (!Clarity.isInited) {
      this.createScript(code);
      Clarity.isInited = true;
    }
  }

  private createScript(code: string) {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', code);
  }
}
