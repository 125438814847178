import HTTP from 'Types/HTTP';
import { ISideMenu, hasChild } from 'Types/ISideMenu';
import { TOnboarding } from 'Types/TOnboarding';
import MSRequest from 'Utils/MSRequest';
import { ICompanyDomain } from 'Types/ICompanyDomain';

export const sideMenuRequest = MSRequest<ISideMenu[]>(HTTP.GET, '/company/menu', {
  responseDataTransform: (arr) =>
    [...arr]
      .sort((a, b) => a.ordem - b.ordem)
      .map((item) => {
        if (hasChild(item)) {
          return {
            ...item,
            childMenuItems: item.childMenuItems.sort((a, b) => a.ordem - b.ordem)
          };
        }
        return item;
      })
});

export const onboardingRequest = MSRequest<TOnboarding>(HTTP.GET, '/company/onboarding/list');

export const companyDomainRequest = MSRequest<ICompanyDomain>(HTTP.GET, '/company/dominioLoja', {
  responseDataTransform: (data) => ({
    ...data,
    customDomain: !data.customDomain ? data.customDomain : data.customDomain.replace(/\/$/, '')
  })
});
