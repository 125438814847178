import { ISideContentContext } from 'Types/ISideContentContext/ISideContentContext';
import { PropsWithChildren, createContext, useState } from 'react';

const SideContentContext = createContext<ISideContentContext>({
  sideContentIsOpen: true,
  toggleSideContentIsOpen: () => {}
});

export const SideContentProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [sideContentIsOpen, toggleSideContentIsOpen] = useState<boolean>(false);

  return (
    <SideContentContext.Provider value={{ sideContentIsOpen, toggleSideContentIsOpen }}>
      {children}
    </SideContentContext.Provider>
  );
};

export default SideContentContext;
