/*
 * This function takes in an object that has information about a route and its nested routes and
 * generates a react-router-dom Route component with that information. It also recursively calls
 * itself to generate any nested routes.
 */

import { IRoutes } from 'Types/IRoutes';
import { Route } from 'react-router-dom';

const GenerateRoute = (routeInfo: IRoutes) => (
  <Route key={routeInfo.name} path={routeInfo.path} element={<routeInfo.pageData.component />}>
    {routeInfo.nestedRoutes &&
      routeInfo.nestedRoutes.map((nestedRoute) => GenerateRoute(nestedRoute))}
  </Route>
);

export default GenerateRoute;
