const ERROR_STATUS = {
  // 400 STATUS
  400: 'Solicitação Inválida', // 'Bad Request',
  401: 'Não autorizado', // 'Unauthorized',
  402: 'Pagamento necessário', // 'Payment Required',
  403: 'Proibido', // 'Forbidden',
  404: 'Não encontrado', // 'Not Found',
  405: 'Método não permitido', // 'Method Not Allowed',
  406: 'Não aceito', // 'Not Acceptable',
  407: 'Autenticação de Proxy Necessária', // 'Proxy Authentication Required',
  408: 'Tempo de solicitação esgotado', // 'Request Timeout',
  409: 'Conflito', // 'Conflict',
  410: 'Perdido', // 'Gone',
  411: 'Duração necessária', // 'Length Required',
  412: 'Falha de pré-condição', // 'Precondition Failed',
  413: 'Solicitação da entidade muito extensa', // 'Payload Too Large',
  414: 'Solicitação de URL muito Longa', // 'URI Too Long',
  415: 'Tipo de mídia não suportado', // 'Unsupported Media Type',
  416: 'Solicitação de faixa não satisfatória', // 'Requested Range Not Satisfiable',
  417: 'Falha na expectativa', // 'Expectation Failed',
  421: 'Solicitação mal direcionada', // 'Misdirected Request',
  422: 'Entidade não processável', // 'Unprocessable Entity',
  423: 'Locked', // 'Locked',
  424: 'Failed Dependency ', // 'Failed Dependency ',
  425: 'Muito cedo', // 'Too Early',
  426: 'Upgrade necessário', // 'Upgrade Required',
  428: 'Pré-condição necessária', // 'Precondition Required',
  429: 'Muitos requests', // 'Too Many Requests',
  431: 'Cabeçário da requisição muito grande', // 'Request Header Fields Too Large',
  451: 'Indisponível por razões legais', // 'Unavailable For Legal Reasons',

  // 500 STATUS
  500: 'Erro interno do servidor', // 'Internal Server Error',
  501: 'Não implementado', // 'Not Implemented',
  502: 'Porta de entrada ruim', // 'Bad Gateway',
  503: 'Serviço Indisponível', // 'Service Unavailable',
  504: 'Tempo limite da Porta de Entrada', // 'Gateway Timeout',
  505: 'Versão HTTP não suportada', // 'HTTP Version Not Supported',
  506: 'Variante também negocia', // 'Variant Also Negotiates',
  507: 'Espaço insuficiente', // 'Insufficient Storage',
  508: 'Laço detectado', // 'Loop Detected ',
  510: 'Não estendido', // 'Not Extended',
  511: 'Autenticação de rede necessária' // 'Network Authentication Required',
} as const;

export const TGERROR_STATUS = (val: unknown): val is keyof typeof ERROR_STATUS =>
  typeof val === 'string' && Object.keys(ERROR_STATUS).some((key) => key === val);

export default ERROR_STATUS;
