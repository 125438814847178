import S from './Gtm.component.style.module.scss';

const Gtm = () => {
  return (
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-MXZKQV3Z"
        className={S.frame}
        height="0"
        width="0"
      ></iframe>
    </noscript>
  );
};

export default Gtm;
