export default function CreateDebouncedFunction() {
  let delay: NodeJS.Timeout;

  return function Debounced<T extends unknown[], K>(
    callback: (...args: T) => K,
    params: T,
    timeout: number
  ): Promise<K> {
    return new Promise<K>((resolve, reject) => {
      if (delay !== null) {
        clearTimeout(delay);
      }

      delay = setTimeout(async () => {
        try {
          resolve(await callback(...params));
        } catch (e) {
          reject(e);
        }
      }, timeout);
    });
  };
}
