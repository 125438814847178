interface ISideMenuItemChild {
  link: string;
  name: string;
  ordem: number;
}

export interface ISideMenuWithChild {
  nome: string;
  idAbaPainelJetPai: string;
  childMenuItems: ISideMenuItemChild[];
  ordem: number;
}

export const hasChild = (item: unknown): item is ISideMenuWithChild => {
  return (item as ISideMenuWithChild).childMenuItems !== undefined;
};

export interface ISideMenuWithoutChild {
  nome: string;
  idAbaPainelJetPai: string;
  to: string;
  ordem: number;
  state?: { notAllowed: boolean };
}

export type ISideMenu = ISideMenuWithChild | ISideMenuWithoutChild;
