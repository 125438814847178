import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

/**
 * This function returns the lazy loaded page component.
 * @param {string} relativePath - An relative path of the page component starting on the Pages folder, without the .page extension.
 */

export default function lazyLoad(relativePath: string) {
  const promise = () => import(`Pages/${relativePath}.page`);
  return {
    promise,
    component: lazy(promise)
  };
}

const SuspenseRouteWrapper: React.FC = () => {
  return (
    <Suspense>
      <Outlet />
    </Suspense>
  );
};

export { SuspenseRouteWrapper };
