/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

export default class Hotjar {
  static isInited = false;

  constructor(code: number) {
    if (!Hotjar.isInited) {
      this.createScript(code);
      Hotjar.isInited = true;
    }
  }

  private createScript(code: number) {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: code, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
}
