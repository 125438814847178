import S from './UserProfile.component.style.module.scss';
import IStore from 'Types/IStore';
import GetInitials from 'Utils/GetInitials';
import meuNegocioLogo from 'Assets/Images/SVG/meu_negocio_logo.svg';
import { Link } from 'react-router-dom';

type Props = {
  actualStore: IStore;
  shouldAllowChangeStore: boolean;
};

export const UserProfileContent = ({ actualStore, shouldAllowChangeStore }: Props) => {
  const initialsOfStoreName = GetInitials(actualStore.fantasia);

  return (
    <>
      <img src={meuNegocioLogo} alt="meu-negocio" />
      <div className={S['store-info']}>
        <h3>Nome da Loja selecionada</h3>
        <h1>{actualStore.fantasia}</h1>
        <div className={`${S.profile} ${S.picture}`}>{initialsOfStoreName}</div>
        <Link className={S['logoff-anchor']} to="/redirect?to=/logoff.asp">
          Sair
        </Link>
        <br />
        {shouldAllowChangeStore && (
          <>
            <hr />
            <h2>Selecione e administre sua loja</h2>
            <a href="https://painel.lojavirtuol.uol.com.br/stores">Alterar loja</a>
          </>
        )}
      </div>
    </>
  );
};

const UserProfile: React.FC<Props> = ({ actualStore, shouldAllowChangeStore }) => {
  const initialsOfStoreName = GetInitials(actualStore.pastaLoja);

  return (
    <div tabIndex={1} className={S.profile}>
      {initialsOfStoreName}
      <div className={S['profile-content']}>
        <UserProfileContent
          actualStore={actualStore}
          shouldAllowChangeStore={shouldAllowChangeStore}
        />
      </div>
    </div>
  );
};

export default UserProfile;
