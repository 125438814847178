import { ReactComponent as successImage } from 'Assets/Images/SVG/success.svg';
import { ReactComponent as errorImage } from 'Assets/Images/SVG/error.svg';
import { ReactComponent as warnImage } from 'Assets/Images/SVG/warn.svg';
import { ReactComponent as infoImage } from 'Assets/Images/SVG/info.svg';
import { toast as toastifyToast } from 'react-toastify';

const proxyHadledMethods = [
  'success',
  'error',
  'info',
  'warn',
  'warning'
] satisfies (keyof typeof toastifyToast)[];

const methodIconMap: Record<
  (typeof proxyHadledMethods)[number],
  React.ComponentType<React.SVGProps<SVGSVGElement>>
> = {
  success: successImage,
  error: errorImage,
  info: infoImage,
  warn: warnImage,
  warning: warnImage
};

const toast = new Proxy(toastifyToast, {
  get: (target, prop) => {
    const requestedMethod = proxyHadledMethods.find((method) => method === prop);
    if (requestedMethod) {
      return (...parameters: Parameters<(typeof target)[typeof requestedMethod]>) => {
        const [title, options, ...rest] = parameters;
        const Icon = methodIconMap[requestedMethod];
        target[requestedMethod](
          title,
          {
            autoClose: requestedMethod == 'error' ? 10000 : 5000,
            icon: <Icon />,
            ...options
          },
          ...rest
        );
      };
    }

    return Reflect.get(target, prop);
  }
});

export default toast;
