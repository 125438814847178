import LogError from 'Utils/LogError';
import Error from 'Pages/Error/Error.page';
import { ErrorBoundary as Boundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

const ErrorBoundary: React.FC = () => {
  return (
    <Boundary fallbackRender={() => <Error />} onError={LogError}>
      <Outlet />
    </Boundary>
  );
};

export default ErrorBoundary;
