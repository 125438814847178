import S from './Probe.page.style.module.scss';

const Probe = () => {
  return (
    <div className={S.container}>
      <h1>Healty</h1>
    </div>
  );
};

export default Probe;
