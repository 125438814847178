import S from './SideMenuItem.component.style.module.scss';
import { ISideMenu, ISideMenuWithChild, ISideMenuWithoutChild, hasChild } from 'Types/ISideMenu';
import GaEvent from 'Utils/GA/event';
import getImage from 'Utils/GetSideMenuImages';
import { Link } from 'react-router-dom';

type Props = {
  toggleIsClosed: (state: boolean) => void;
  actualOpenMenu: string;
  changeActualOpenMenu: React.Dispatch<React.SetStateAction<string>>;
  item: ISideMenu;
};

type PropsMenuItemWithSubItems = Props & {
  item: ISideMenuWithChild;
};

const MenuItemWithSubItems: React.FC<PropsMenuItemWithSubItems> = ({
  item,
  actualOpenMenu,
  changeActualOpenMenu,
  toggleIsClosed
}) => {
  return (
    <div
      key={item.idAbaPainelJetPai}
      data-testid="main-nav-item"
      className={`${S['main-nav-item']} ${item.nome === 'Aplicativos' ? S.apps : ''} ${
        item.idAbaPainelJetPai === actualOpenMenu ? S.selected : ''
      }`}
    >
      <GaEvent
        trigger="onClick"
        category="Menu Lateral"
        action="Clique"
        label={`Menu ${item.nome}`}
      >
        <div
          onClick={() => {
            changeActualOpenMenu((prev) =>
              prev === item.idAbaPainelJetPai ? '' : item.idAbaPainelJetPai
            );
            toggleIsClosed(false);
          }}
          className={`${S['main-nav-item-title']}`}
        >
          <img src={getImage(item.idAbaPainelJetPai)} alt={item.nome} />
          <h1>{item.nome}</h1>
        </div>
      </GaEvent>
      <div className={S['item-options']}>
        {item.childMenuItems.map((child) => (
          <GaEvent
            trigger="onClick"
            action="Clique"
            category="Menu Lateral"
            label={`Sub-menu ${item.nome} - ${child.name}`}
            key={child.name}
          >
            <div className={`${S['sub-menu-item']} ${S.selected}`}>
              {!child.link.includes('http') ? (
                <Link to={child.link.includes('asp') ? `/redirect?to=${child.link}` : child.link}>
                  {child.name}
                </Link>
              ) : (
                <a target="_blank" href={child.link}>
                  {child.name}
                </a>
              )}
            </div>
          </GaEvent>
        ))}
      </div>
    </div>
  );
};

type PropsMenuItemWithoutSubItems = Props & {
  item: ISideMenuWithoutChild;
};

const MenuItemWithoutSubItems: React.FC<PropsMenuItemWithoutSubItems> = ({
  item,
  actualOpenMenu,
  changeActualOpenMenu,
  toggleIsClosed
}) => {
  const { state } = item;
  const linkState = state ? { state } : {};

  return (
    <div
      key={item.idAbaPainelJetPai}
      data-testid="main-nav-item"
      className={`${S['main-nav-item']} ${item.nome === 'Aplicativos' ? S.apps : ''} ${
        item.idAbaPainelJetPai === actualOpenMenu ? S.selected : ''
      }`}
    >
      <GaEvent
        trigger="onClick"
        category="Menu Lateral"
        action="Clique"
        label={`Menu ${item.nome}`}
      >
        <Link
          onClick={() => {
            changeActualOpenMenu((prev) =>
              prev === item.idAbaPainelJetPai ? '' : item.idAbaPainelJetPai
            );
            toggleIsClosed(false);
          }}
          className={`${S['main-nav-item-title']}`}
          to={item.to.includes('.asp') ? `/redirect?to=${item.to}` : item.to}
          {...linkState}
        >
          <img src={getImage(item.idAbaPainelJetPai)} alt={item.nome} />
          <h1>{item.nome}</h1>
        </Link>
      </GaEvent>
    </div>
  );
};

const SideMenuItem: React.FC<Props> = (props) => {
  if (hasChild(props.item)) {
    return <MenuItemWithSubItems {...props} item={props.item} />;
  }
  return <MenuItemWithoutSubItems {...props} item={props.item} />;
};

export default SideMenuItem;
