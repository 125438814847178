import S from './Error.page.style.module.scss';
import ERROR_STATUS, { TGERROR_STATUS } from 'Utils/ErrorStatus';
import astronaut from 'Assets/Images/SVG/astronaut.svg';
import moon from 'Assets/Images/SVG/moon.svg';
import { useSearchParams, Navigate } from 'react-router-dom';
import { Button } from 'uol-host-react-ui';

const Error = () => {
  const [params] = useSearchParams();

  const code = params.get('code');

  if (!code || !TGERROR_STATUS(code)) {
    return <Navigate to="/error?code=500" />;
  }

  localStorage.clear();

  const errorMessage = ERROR_STATUS[code];

  return (
    <>
      <div className={S.container}>
        <h1>{code}</h1>
        <h2>{errorMessage}</h2>
        <Button variant="aqua" as="a" href="https://painel.lojavirtuol.uol.com.br/stores">
          Voltar
        </Button>
      </div>
      <img src={astronaut} alt="astronaut" />
      <img src={moon} alt="moon" />
    </>
  );
};

export default Error;
