/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

export default class Helper {
  static isInited = false;

  constructor(id: string, type = 'square') {
    if (!Helper.isInited) {
      this.createScript(id, type);
      Helper.isInited = true;
    }
  }

  private createScript(id: string, type: string) {
    (function (t, f, a, x, ty) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        t.setAttribute('onload', `embedding("${x}", "${ty}")`);
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement('script'),
      document.getElementsByTagName('script')[0],
      'https://widgets.helper.uol.com.br/script-widget.min.js',
      id,
      type
    );
  }
}
