import puzzlePiece from 'Assets/Images/SVG/puzzle-piece.svg';
import bullsEye from 'Assets/Images/SVG/icone-3.svg';
import cog from 'Assets/Images/SVG/cog.svg';
import edit from 'Assets/Images/SVG/edit.svg';
import chartPie from 'Assets/Images/SVG/chart-pie.svg';
import userAlt from 'Assets/Images/SVG/user-alt.svg';
import shoppingCart from 'Assets/Images/SVG/shopping-cart.svg';
import star from 'Assets/Images/SVG/star.svg';
import seta from 'Assets/Images/SVG/set_esq.svg';
import home from 'Assets/Images/SVG/house-solid.svg';
import bookmark from 'Assets/Images/SVG/bookmark.svg';

const getImage = (imgAddress: string) => {
  let img = '';

  switch (imgAddress) {
    case '9':
      img = home;
      break;
    case '5':
      img = puzzlePiece;
      break;
    case '8':
      img = cog;
      break;
    case '2':
      img = edit;
      break;
    case '4':
      img = userAlt;
      break;
    case '3':
      img = shoppingCart;
      break;
    case '6':
      img = star;
      break;
    case '7':
      img = bullsEye;
      break;
    case '10':
      img = bookmark;
      break;
    case 'seta':
      img = seta;
      break;
    default:
      img = chartPie;
      break;
  }

  return img;
};

export default getImage;
