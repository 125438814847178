import AxiosRequest from 'Utils/AxiosRequest';

function stringifyError(error: Error): string {
  const plainError: { [key: string]: unknown } = {};

  Object.getOwnPropertyNames(error).forEach((key) => {
    plainError[key] = error[key as keyof Error];
  });

  return JSON.stringify(plainError);
}

export default function LogError(error: unknown) {
  if (process.env.NODE_ENV === 'production') {
    AxiosRequest.post(
      '/log/log/gravar',
      {
        idEmpresa: AxiosRequest.defaults.headers['company-id'] || 1,
        descricao: error instanceof Error ? stringifyError(error) : error,
        dataCadastro: new Date(),
        origem: `Painel ADM React - ${window.location.href}`,
        agent: window.navigator.userAgent
      },
      {
        headers: {
          'company-id': AxiosRequest.defaults.headers['company-id'] || 1,
          'pool-id': AxiosRequest.defaults.headers['pool-id'] || 1
        }
      }
    ).catch(console.log);
  } else {
    console.error(error);
  }
}
