import useStore from 'Hooks/useStore';
import IsEmpty from 'Utils/IsEmpty';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

const RequireStore = () => {
  const { store } = useStore();
  const { pathname, search } = useLocation();

  if (IsEmpty(store)) {
    return (
      <Navigate
        to="/stores"
        state={pathname !== '/stores' ? { prevLocation: pathname + search } : null}
      />
    );
  }

  return <Outlet />;
};

export default RequireStore;
