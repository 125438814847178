import S from './AppDrawer.component.style.module.scss';
import useUi from 'Hooks/useUi';

const AppDrawer = () => {
  const { showDrawer, setShowDrawer, setDrawerChildren, drawerChildren } = useUi();

  const handleClick = () => {
    setShowDrawer(false);
    setDrawerChildren([]);
    window.scrollTo(0, 0);
  };

  return (
    <div className={`${S.container} ${showDrawer ? S.open : ''}`}>
      <div className={S.backdrop} onClick={handleClick}></div>
      {drawerChildren?.map((children, index) => (
        <div key={index} className={S['drawer-content']}>
          {children}
        </div>
      ))}
    </div>
  );
};

export default AppDrawer;
