import S from './SideContent.component.style.module.scss';
import { reportsRequest } from 'Services/ReportRequests';
import { IReportResponse } from 'Types/IReport';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

type Props = {
  isLoading: boolean;
  handleLoading: (bool: boolean) => void;
  period: string;
  groupBy: 'Day' | 'Week' | 'Month' | 'Year';
};

const tableHeader = ['Período', 'Visitas', 'Pedidos', 'Valor Total'];

const tableBody: string[][] = [
  ['Jan 00', '00000', '0000', 'R$000'],
  ['Jan 00', '00000', '0000', 'R$000'],
  ['Jan 00', '00000', '0000', 'R$000'],
  ['Jan 00', '00000', '0000', 'R$000'],
  ['Jan 00', '00000', '0000', 'R$000'],
  ['Jan 00', '00000', '0000', 'R$000']
];

const formatDate = (responseDate: Date, count: number, groupBy: Props['groupBy']) => {
  const newDate = new Date(responseDate);

  if (groupBy === 'Month') {
    return newDate
      .toLocaleDateString('pt-BR', { month: 'short', year: '2-digit' })
      .replace('. de', '');
  }

  return count > 7
    ? newDate.toLocaleDateString('pt-BR').slice(0, 5)
    : // newDate.toLocaleDateString('pt-BR', { day: '2-digit', month: 'short' })
      newDate.toLocaleDateString('pt-BR', { weekday: 'short' }).slice(0, 3);
};

const parseTableData = (response: IReportResponse[], groupBy: Props['groupBy']) => {
  const newData: string[][] = [];

  response.sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)));

  for (const datas of response) {
    const newArr: string[] = [];
    const { data } = datas;

    newArr.push(formatDate(data.date, response.length, groupBy));
    newArr.push(data.visits);
    newArr.push(data.order);
    newArr.push(data.totalOrderValue);
    newData.push(newArr);
  }

  return newData;
};

const SideContentTable: React.FC<Props> = (props) => {
  const { isLoading, handleLoading, period, groupBy } = props;
  const [tableData, setData] = useState(tableBody);

  const payload = {
    // IdCompany: '18001039',
    StartDate: period,
    GroupBy: groupBy
  };

  useQuery(['reportTableData', period], reportsRequest(payload), {
    onError() {
      handleLoading(false);
    },
    onSuccess(response) {
      setTimeout(() => {
        setData(parseTableData(response, groupBy));
        handleLoading(false);
      }, 200);
    },
    cacheTime: 300000
  });

  useEffect(() => {
    if (isLoading) {
      setData(tableBody);
    }
  }, [isLoading]);

  return (
    <div className={`${S['table-wrap']} ${isLoading && S['is-loading']}`} data-testid="reportTable">
      <div className={S['resp-table']}>
        <div className={S['resp-table-header']}>
          {tableHeader.map((el, i) => (
            <div key={i} className={S['table-header-cell']} data-testid="reportTableHeaderItem">
              {el}
            </div>
          ))}
        </div>
        <div className={S['resp-table-body']}>
          {tableData?.map((row, i) => (
            <div key={i} className={S['resp-table-row']}>
              {row?.map((cell, index) => (
                <div key={index} className={S['table-body-cell']}>
                  <span>{cell}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideContentTable;
