const GetCookie = (cookieName: string) => {
  // Get the cookie string for the given name
  const cookieString = document.cookie
    .split(';')
    .find((cookie) => cookie.split('=')[0].includes(cookieName));

  // If the cookie string was not found, return null
  if (!cookieString) {
    return null;
  }

  // Split the cookie string into an array
  const cookieInfo = cookieString.split('=')[1].split('|');

  // Return the first element of the array
  return {
    value: cookieInfo[0]
  };
};

export default GetCookie;
