import S from './AppModal.component.style.module.scss';
import useUi from 'Hooks/useUi';
import { Modal } from 'uol-host-react-ui';

const AppModal = () => {
  const { showModal, setShowModal, modalChildren } = useUi();

  return (
    <Modal show={showModal} onClose={() => setShowModal(false)} className={S['app-modal']}>
      {modalChildren}
    </Modal>
  );
};

export default AppModal;
