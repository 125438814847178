import S from './SideMenu.component.style.module.scss';

import SideMenuItem from '../SideMenuItem/SideMenuItem.component';
import getImage from 'Utils/GetSideMenuImages';
import { type ISideMenu } from 'Types/ISideMenu';
import virtuolLogo from 'Assets/Images/PNG/logo-criando-sua-loja.png';
import gotoStore from 'Assets/Images/SVG/goto-store.svg';
import useStore from 'Hooks/useStore';
import { companyDomainRequest } from 'Services/PrefetchRequests';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Spin } from 'uol-host-react-ui';

type Props = {
  items: ISideMenu[] | undefined;
  isOpen: boolean;
  toggleIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SideMenu: React.FC<Props> = ({ items, isOpen, toggleIsOpen }) => {
  const [actualOpenMenu, changeActualOpenMenu] = useState<string>('0');
  const [shouldShowBoxShadow, toggleShouldShowBoxShadow] = useState<boolean>(false);
  const newItems = [...(items || [])];

  if (!items?.some((item) => item.nome === 'Inicio')) {
    newItems?.unshift({
      nome: 'Inicio',
      idAbaPainelJetPai: '9',
      ordem: 0,
      to: '/',
      state: { notAllowed: true }
    });
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    toggleShouldShowBoxShadow(!!element.scrollTop);
  };

  const { store } = useStore();
  const { data: companyDomain } = useQuery(['companyDomain'], companyDomainRequest, {
    refetchInterval(data) {
      if (!data?.isDomainAvailable) {
        return 6000;
      }
      return false;
    }
  });

  const dominioLoja = useMemo(() => {
    if (!companyDomain) {
      return undefined;
    }

    if (companyDomain.isDomainAvailable) {
      return companyDomain.customDomain || `https://${store.pastaLoja}.lojavirtuolpro.com`;
    }
  }, [companyDomain, store.pastaLoja]);

  return (
    <>
      {isOpen && <div id="back-menu" className={S.back} onClick={() => toggleIsOpen(false)} />}
      <div data-testid="side-menu" id="side-menu" className={!isOpen ? 'compact' : ''}>
        <div className={S['img-container']}>
          <Link to="/">
            {!isOpen ? (
              <img className="compact" src="/favicon.ico" alt="logo" />
            ) : (
              <img className="expanded" src={virtuolLogo} alt="logo" />
            )}
          </Link>
        </div>
        {dominioLoja ? (
          <div
            className={`${S['goto-store-container']} 
              ${shouldShowBoxShadow ? S['box-shadow'] : ''}`}
          >
            <a href={dominioLoja} target="_blank">
              <img src={gotoStore} alt="" />
              <p>Acessar a loja</p>
            </a>
          </div>
        ) : (
          <div
            className={`${S['loader-container']} 
          ${shouldShowBoxShadow ? S['box-shadow'] : ''}`}
          >
            <Spin />
            <p>Publicando sua loja</p>
          </div>
        )}
        <div onScroll={handleScroll} data-testid="nav-items" className={S['nav-items']}>
          {newItems?.map((item) => (
            <SideMenuItem
              key={item.idAbaPainelJetPai}
              item={item}
              actualOpenMenu={actualOpenMenu}
              changeActualOpenMenu={changeActualOpenMenu}
              toggleIsClosed={(val) => toggleIsOpen(!val)}
            />
          ))}
        </div>
        <div
          data-testid="close-nav"
          onClick={() => toggleIsOpen((state) => !state)}
          className={S['close-nav']}
        >
          <img id="close-arrow" src={getImage('seta')} alt="" />
        </div>
      </div>
    </>
  );
};

export default SideMenu;
