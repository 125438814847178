import S from './UolProducts.component.style.module.scss';
import dominiosLogo from 'Assets/Images/SVG/dominios-logo.svg';
import anunciosLogo from 'Assets/Images/SVG/anuncios-logo.svg';
import emailLogo from 'Assets/Images/SVG/email-icon.svg';
import facebookLogo from 'Assets/Images/SVG/facebook.svg';
import instagramLogo from 'Assets/Images/PNG/instagram.png';
import pinterestLogo from 'Assets/Images/SVG/pinterest.svg';
import linkedinLogo from 'Assets/Images/SVG/linkedin.svg';
import youtubeLogo from 'Assets/Images/SVG/youtube.svg';
import tiktokLogo from 'Assets/Images/SVG/tiktok.svg';
import logoUol from 'Assets/Images/PNG/logo-uol.png';

export const UolProductsContent: React.FC = () => (
  <>
    <h1>Outros produtos UOL</h1>
    <p>
      Sua presença na internet é essencial para o seu sucesso. Conheça os produtos que vão ajudar o
      seu negócio a se destacar virtualmente.
    </p>
    <div className={S['products-grid']}>
      <a
        href="https://meupainelhost.uol.com.br/marketplace/product/e-mail?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        target="_blank"
      >
        <div className={S['product-card']}>
          <img src={emailLogo} alt="email" />
          <h2>E-mail Profissional</h2>
        </div>
      </a>
      <a
        href="https://uolhost.uol.com.br/registro-de-dominio?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        target="_blank"
      >
        <div className={S['product-card']}>
          <img src={dominiosLogo} height="34px" alt="dominios" />
          <h2>Domínios</h2>
        </div>
      </a>
      <a
        href="https://meupainelhost.uol.com.br/marketplace/product/uol-anuncios?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        target="_blank"
      >
        <div className={S['product-card']}>
          <img src={anunciosLogo} alt="anuncios" />
          <h2>UOL Anúncios</h2>
        </div>
      </a>
      <div className={S['product-card-large']}>
        <h1>É hora de expandir!</h1>
        <h2>Passe para o próximo nível: O crescimento.</h2>
        <a
          href="https://meupainelhost.uol.com.br/marketplace?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
          target="_blank"
        >
          Encontre outros produtos
        </a>
      </div>
      <div className={S['product-card-large']}>
        <h1>Marketing Digital</h1>
        <h2>
          Como usar o e-mail marketing para captar compras não finalizadas e potencializar suas
          vendas.
        </h2>
        <a
          href="https://meunegocio.uol.com.br/blog/o-que-e-marketing-digital-e-por-que-voce-precisa-investir-nele-agora/"
          target="_blank"
        >
          Aprenda clicando aqui
        </a>
      </div>
    </div>
    <div className={S.social}>
      <h1>Acompanhe as novidades</h1>
      <div className={S['social-icons']}>
        <a
          className={S.blog}
          target="_blank"
          href="https://meunegocio.uol.com.br/blog/?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        >
          Blog
        </a>
        <a
          className={S.instagram}
          target="_blank"
          href="https://www.instagram.com/uolhost?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        >
          <img src={instagramLogo} alt="Instagram" />
        </a>
        <a
          className={S.facebook}
          target="_blank"
          href="https://www.facebook.com/uolhost?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        >
          <img src={facebookLogo} alt="Facebook" />
        </a>
        <a
          className={S.youtube}
          target="_blank"
          href="https://www.youtube.com/user/uolhost?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        >
          <img src={youtubeLogo} alt="Youtube" />
        </a>
        <a
          className={S.linkedin}
          target="_blank"
          href="https://www.linkedin.com/showcase/uolhost/?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        >
          <img src={linkedinLogo} alt="Linkedin" />
        </a>
        <a
          className={S.tiktok}
          target="_blank"
          href="https://www.tiktok.com/@uolhost?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        >
          <img src={tiktokLogo} alt="TikTok" />
        </a>
        <a
          className={S.pinterest}
          target="_blank"
          href="https://br.pinterest.com/uolmeunegocio/?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
        >
          <img src={pinterestLogo} alt="Pinterest" />
        </a>
      </div>
    </div>
  </>
);

const UolProducts: React.FC = () => {
  return (
    <div tabIndex={0} className={S['uol-products']}>
      <img src={logoUol} alt="logo-uol" />
      <div className={S['uol-products-content']}>
        <UolProductsContent />
      </div>
    </div>
  );
};

export default UolProducts;
