import S from './Footer.component.style.module.scss';

const Footer: React.FC = () => {
  const programs = [
    { link: 'https://uolhost.uol.com.br/parceiros/', text: 'Parcerias' },
    { link: 'https://uolhost.uol.com.br/afiliados/', text: 'Afiliados' }
  ];

  const socialMidia = [
    { link: 'https://meunegocio.uol.com.br/blog/', text: 'Blog' },
    { link: 'https://www.youtube.com/user/uolhost', text: 'Youtube' }
  ];

  const socialMidia2 = [
    { link: 'https://www.instagram.com/uolmeunegocio/', text: 'Instagram' },
    { link: 'https://www.facebook.com/uolhost', text: 'Facebook' }
  ];

  const telephone = [
    { text: '4003 9011', subtext: 'Capitais e regiões metropolitanas', tel: '40039011' },
    { text: '0800 881 9011', subtext: 'Demais localidades', tel: '08008819011' },
    {
      text: 'Suporte técnico 24 horas. 7 dias por semana',
      subtext: 'Dúvidas financeiras seg-sex, 8h às 22h40'
    }
  ];

  const copyRight = {
    title: `© 1996 - ${new Date().getFullYear()} UOL - Seu universo online.`,
    subtitle: 'Todos os direitos reservados. Segurança e privacidade.'
  };

  return (
    <main className={S['footer-main']}>
      <div className={S['footer-container']}>
        <div className={S['footer-wrapper']}>
          <div className={S['wrapper-content']}>
            <div className={`${S['content-div']} ${S.program}`}>
              <p className={S.title}>Nossos programas</p>
              <ul className={S['link-list-ul']}>
                {programs.map((item) => (
                  <li key={item.text}>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <span>{item.text}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={`${S['content-div']} ${S['social-media']}`}>
              <p className={S.title}>Acompanhe as novidades</p>
              <div className={S['social-media-list']}>
                <ul className={S['link-list-ul']}>
                  {socialMidia.map((item) => (
                    <li key={item.text}>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <span>{item.text}</span>
                      </a>
                    </li>
                  ))}
                </ul>
                <ul className={S['link-list-ul']}>
                  {socialMidia2.map((item) => (
                    <li key={item.text}>
                      <a href={item.link} target="_blank" rel="noreferrer">
                        <span>{item.text}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={`${S['content-div']} ${S.contact}`}>
              <p className={S.title}>Fale Conosco</p>
              <ul className={S['link-list-ul']}>
                <li>
                  <a
                    href="https://ouvidoria.uol.com.br/rest/skin/ouvidoria-host/index.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Ouvidoria</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className={`${S['content-div']} ${S.telephone}`}>
              <p className={S.title}>Telefones</p>
              <ul className={`${S['link-list-ul']} ${S.telephone}`}>
                {telephone.map((item) => (
                  <li key={item.text}>
                    {item.tel ? (
                      <a href={`tel:${item.tel}`}>
                        <span>{item.text}</span>
                      </a>
                    ) : (
                      <p className={S['text-litle']}>{item.text}</p>
                    )}
                    <p className={S['text-litle']}>{item.subtext}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <hr className={S['content-div']} />
          <div className={S['content-div-logo']}>
            <a href="https://meupainelhost.uol.com.br/" target="_blank">
              <img
                src="https://a.meupainelhost.uol.com.br/icons-products/umn_host_logo_dark.gif"
                alt="Logo Uol Host/Meu Negócio"
                height="32"
              />
            </a>
            <div className={S['copy-right']}>
              <p className={S['copy-right-text']}>{copyRight.title}</p>
              <p className={S['text-litle']}>{copyRight.subtitle}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Footer;
