import S from './WelcomeModal.component.style.module.scss';
import logoUol from '../../../Assets/Images/PNG/logo-virtuol.png';
import helloGirl from '../../../Assets/Images/SVG/hello_girl.svg';
import { Modal } from 'uol-host-react-ui';
import { useState } from 'react';

type Props = {
  storeName: string;
};

const WelcomeModal: React.FC<Props> = ({ storeName }) => {
  const [isOpen, setIsOpen] = useState<boolean>(!localStorage.getItem('welcomeModal'));
  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem('welcomeModal', 'true');
  };

  return (
    <Modal onClose={handleClose} show={isOpen}>
      <header className={S.headerStyle}>
        <img src={logoUol} alt="logoUol" />
        <p></p>
      </header>
      <aside className={S.welcome}>
        <img src={helloGirl} alt="" />
        <div className={S.welcomeContent}>
          <p>Boas-vindas à sua Loja VirtUOL</p>
          <span>Sua loja está pronta para ser configurada</span>
        </div>
      </aside>
      <article className={S.contentLoja}>
        <p>
          Olá {storeName}.
          <br />
          <br />
          Nossa missão é simplificar, aumentar a produtividade e rentabilidade em sua vida por meio
          da Loja VirtUOL.
        </p>
        <span></span>
      </article>
      <footer>
        <p>Equipe Loja VirtUOL</p>
      </footer>
    </Modal>
  );
};

export default WelcomeModal;
