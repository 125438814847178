import S from './Search.component.style.module.scss';
import MSRequest from 'Utils/MSRequest';
import magnifier from 'Assets/Images/SVG/magnifier.svg';
import HTTP from 'Types/HTTP';
import { type TSearch } from 'Types/TSearch';
import CreateDebouncedFunction from 'Utils/CreateDebouncedFunction';
import useUi from 'Hooks/useUi';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import React, { useRef } from 'react';

const Search: React.FC = () => {
  const [search, setSearch] = React.useState<string>('');

  const { sideMenuIsOpen } = useUi();

  const { current: Debounced } = useRef(CreateDebouncedFunction());

  const { data } = useQuery(
    ['search', search],
    MSRequest<TSearch[]>(HTTP.GET, `/company/search?search=${encodeURIComponent(search)}`),
    { keepPreviousData: true, enabled: !!search }
  );

  return (
    <div data-testid="search" className={`${S.search} ${sideMenuIsOpen ? S.hidden : ''}`}>
      <img src={magnifier} className={S.magnifier} alt="lupa-pesquisa" />
      <input
        type="text"
        onChange={(e) => {
          Debounced(setSearch, [e.target.value], 50);
        }}
        className={S.txtBuscaMenu}
        placeholder="Buscar"
      />
      {search && Array.isArray(data) && (
        <div className={S.list}>
          <>
            {data.map((item) => (
              <React.Fragment key={item.name}>
                <Link to={`/redirect?to=${item.link}`} className={S.element}>
                  <h1 className={S.category}>{item.tabName}</h1>
                  <h2 className={S.name}>{item.name}</h2>
                </Link>
                <hr />
              </React.Fragment>
            ))}
            {!data.length && (
              <div className={S.element}>
                <h1 className={S.category}>Não encontrado</h1>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default Search;
