import isGatewayAuthError from 'Utils/IsGatewayAuthError';
import ForceLogin from 'Utils/RedirectLogin';
import axios, { AxiosInstance } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default class ClientBuilder {
  private readonly instance: AxiosInstance;

  constructor() {
    this.instance = axios.create();
  }

  withBaseURL(baseURL: string) {
    this.instance.defaults.baseURL = baseURL;
    return this;
  }

  withDefaults() {
    this.instance.defaults.withCredentials = true;

    this.instance.interceptors.request.use((config) => {
      const newConfig = config;
      if (newConfig.headers) {
        newConfig.headers['Correlation-ID'] = uuidv4();
        newConfig.headers['Impersonate-Token'] = uuidv4();
      }
      return newConfig;
    });

    this.instance.interceptors.response.use(
      (successData) => successData,
      (error) => {
        if (isGatewayAuthError(error)) {
          ForceLogin();
        }
        return Promise.reject(error);
      }
    );
    return this;
  }

  build() {
    return this.instance;
  }
}
