import { AxiosError } from 'axios';

const gatewayErrorCodes = [401, 403, 412];

const gatewayErrorKeys = ['timestamp', 'path', 'status', 'error', 'requestId'];

export default function isGatewayAuthError(error: AxiosError): boolean {
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.data &&
    Object.keys(error.response.data).every((key) => gatewayErrorKeys.includes(key))
  ) {
    return gatewayErrorCodes.includes(error.response.status);
  }

  return false;
}
