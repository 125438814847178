import UiContext from 'App/Global/Context/UiContext';
import { useContext, useEffect } from 'react';

const useUi = () => {
  const context = useContext(UiContext);
  const { reset } = context;
  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);
  return context;
};

export default useUi;
