import { event, EventArgs } from 'react-ga';

import React, { PropsWithChildren } from 'react';

type Trigger = keyof Omit<React.DOMAttributes<HTMLElement>, 'children' | 'dangerouslySetInnerHTML'>;

type Props = PropsWithChildren<EventArgs & { trigger: Trigger }>;

const GaEvent: React.FC<Props> = ({ children, trigger, ...gaProps }) => {
  if (!React.isValidElement(children)) {
    throw new Error('GaEvent must have a valid child');
  }

  return React.cloneElement(children, {
    ...children.props,
    [trigger]: (ogParams: React.SyntheticEvent) => {
      event(gaProps);

      const ogFunc = children.props[trigger] as React.EventHandler<React.SyntheticEvent>;

      if (ogFunc) ogFunc(ogParams);
    }
  });
};

export default GaEvent;
