import S from './ContextMenus.component.style.module.scss';
import HeaderNav from './HeaderNav.components';
import UserStyle from '../UserProfile/UserProfile.component.style.module.scss';
import ProductsStyle from '../UolProducts/UolProducts.component.style.module.scss';
import UolProducts, { UolProductsContent } from '../UolProducts/UolProducts.component';
import UserProfile, { UserProfileContent } from '../UserProfile/UserProfile.component';
import IStore from 'Types/IStore';
import GetInitials from 'Utils/GetInitials';
import { useState } from 'react';

type Props = {
  actualStore: IStore;
  shouldAllowChangeStore: boolean;
};

const DynamicContextMenu = ({ actualStore, shouldAllowChangeStore }: Props) => {
  const [actualContext, changeActualContext] = useState<'user' | 'products'>('user');
  const initialsOfStoreName = GetInitials(actualStore.pastaLoja);

  const isUserContext = actualContext === 'user';

  const classNameContent = isUserContext
    ? UserStyle['profile-content']
    : ProductsStyle['uol-products-content'];
  return (
    <div
      tabIndex={1}
      style={{ height: '28px' }}
      className={`${
        isUserContext ? UserStyle.profile : `${ProductsStyle['uol-products']} ${UserStyle.profile}`
      } `}
    >
      {initialsOfStoreName}
      <div className={classNameContent}>
        <form
          onChange={(e) => {
            const target = e.target as HTMLInputElement;
            changeActualContext(target.value as 'user' | 'products');
          }}
          className={S['context-controller']}
        >
          <label htmlFor="context-controller-user">
            Minha Conta
            <input
              type="radio"
              name="context-controller"
              value="user"
              id="context-controller-user"
              defaultChecked
            />
          </label>
          <label htmlFor="context-controller-products">
            Produtos UOL
            <input
              type="radio"
              name="context-controller"
              value="products"
              id="context-controller-products"
            />
          </label>
        </form>
        {isUserContext ? (
          <UserProfileContent
            actualStore={actualStore}
            shouldAllowChangeStore={shouldAllowChangeStore}
          />
        ) : (
          <UolProductsContent />
        )}
      </div>
    </div>
  );
};

const ContextMenus: React.FC<Props> = ({ actualStore, shouldAllowChangeStore }) => {
  return (
    <>
      <HeaderNav />
      <div className={`${S['arrow-animation-wrapper']} ${S.mono}`}>
        <UserProfile actualStore={actualStore} shouldAllowChangeStore={shouldAllowChangeStore} />
      </div>
      <div className={`${S['arrow-animation-wrapper']} ${S.mono}`}>{<UolProducts />}</div>
      <div className={`${S['arrow-animation-wrapper']} ${S.dynamic}`}>
        <DynamicContextMenu
          actualStore={actualStore}
          shouldAllowChangeStore={shouldAllowChangeStore}
        />
      </div>
    </>
  );
};

export default ContextMenus;
