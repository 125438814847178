import S from './Header.component.style.module.scss';
import ContextMenus from '../ContextMenus/ContextMenus.component';
import Search from '../Search/Search.component';
import virtuolLogo from 'Assets/Images/PNG/logo-criando-sua-loja.png';
import hamburguerMenu from 'Assets/Images/PNG/hamburguer-menu.png';

import IStore from 'Types/IStore';
import useUi from 'Hooks/useUi';
import { Link } from 'react-router-dom';

type Props = {
  actualStore: IStore;
  shouldAllowChangeStore: boolean;
  sideMenuIsOpen: boolean;
  toggleSideMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header: React.FC<Props> = (props) => {
  const { toggleSideMenuIsOpen, sideMenuIsOpen } = props;
  const { customLeftHeaderItem, customRightHeaderItem } = useUi();
  return (
    <header className={S.header} data-testid="header">
      <div className={S['custom-left-header-item-container']}>{customLeftHeaderItem}</div>
      <div style={customLeftHeaderItem ? { display: 'none' } : {}} className={S.mobile}>
        <button onClick={() => toggleSideMenuIsOpen((prev) => !prev)}>
          <img src={hamburguerMenu} alt="menu" />
        </button>
        <Link to="/">
          <img src={virtuolLogo} alt="logo" />
        </Link>
        <div className={`${S.divisor} ${sideMenuIsOpen ? S.hidden : ''}`} />
      </div>
      <div className={S['header-content']}>
        <div
          className={`${S['search-container']} ${customLeftHeaderItem ? S['mobile-hidden'] : ''}`}
        >
          <Search />
        </div>
        <div
          className={`${S['context-menu-container']} ${
            customRightHeaderItem ? S['mobile-hidden'] : ''
          }`}
        >
          <ContextMenus {...props} />
        </div>
        {customRightHeaderItem && (
          <div className={`${S['custom-right-header-item-container']}`}>
            {customRightHeaderItem}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
