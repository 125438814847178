import ToCurrency from 'Utils/ToCurrency';
import HTTP from 'Types/HTTP';
import { IReportResponse, IReportPayload } from 'Types/IReport';
import MSRequest from 'Utils/MSRequest';

const LOCAL_DATE_FORMAT = 'fr-CA';

/*
|=======================================
| Helper Functions
|=======================================
*/
const dataTransform = (response: IReportResponse[]) => {
  return response.map((val) => {
    val.data = {
      date: val.date,
      visits: val.visits.visitas.toString(),
      order: val.orders.pedidos.toString(),
      totalOrderValue: ToCurrency(val.orders.valorTotal),
      mediumTicket: ToCurrency(val.orders.ticketMedio)
    };
    return val;
  });
};

export const getStartDate = (count: number, type: 'month' | 'day' = 'month') => {
  let currentDate = new Date();

  if (type === 'month') {
    currentDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - count,
      new Date().getDate()
    );
  } else if (type === 'day') {
    currentDate.setDate(currentDate.getDate() - count);
  }

  return currentDate.toLocaleDateString(LOCAL_DATE_FORMAT);
};

/*
|=======================================
| Requests Functions
|=======================================
*/
export const reportsRequest = (payload: IReportPayload = {}) => {
  if (!payload.EndDate) {
    const currentDate = new Date();
    payload.EndDate = currentDate.toLocaleDateString(LOCAL_DATE_FORMAT);
  }

  const reportParams = new URLSearchParams({ ...payload }).toString();

  return MSRequest<IReportResponse[]>(HTTP.GET, `/reports/Reports/Details?${reportParams}`, {
    responseDataTransform: (response) => {
      return dataTransform(response);
    }
  });
};
