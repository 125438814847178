import { IUiContext } from 'Types/IUiContext/IUiContext';
import toast from 'Utils/Toast';
import { IBreadcrumb } from 'Types/BreadcrumbData';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const UiContext = createContext<IUiContext>({
  sideMenuIsOpen: window.innerWidth > 1024,
  toggleSideMenuIsOpen: () => {},
  customLeftHeaderItem: null,
  setCustomLeftHeaderItem: () => {},
  customRightHeaderItem: null,
  setCustomRightHeaderItem: () => {},
  showModal: false,
  setShowModal: () => {},
  modalChildren: null,
  setModalChildren: () => {},
  showDrawer: false,
  setShowDrawer: () => {},
  drawerChildren: [],
  setDrawerChildren: () => {},
  reset: () => {},
  toast,
  breadcrumbContent: []
});

export const UiContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [sideMenuIsOpen, toggleSideMenuIsOpen] = useState<boolean>(window.innerWidth > 1024);
  const [customLeftHeaderItem, setCustomLeftHeaderItem] = useState<React.ReactNode>(null);
  const [customRightHeaderItem, setCustomRightHeaderItem] = useState<React.ReactNode>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalChildren, setModalChildren] = useState<React.ReactNode>(null);
  const [breadcrumbContent, setBreadcrumbContent] = useState<IBreadcrumb[]>([]);
  const { pathname } = useLocation();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [drawerChildren, setDrawerChildren] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    if (navigator.userAgent.includes('Firefox')) {
      const app = document.getElementById('app');
      if (sideMenuIsOpen && app) {
        document.body.style.marginLeft = '0';
      } else {
        document.body.style.marginLeft = '-13.5rem';
      }
    }
  }, [sideMenuIsOpen]);

  useLayoutEffect(() => {
    setBreadcrumbContent([]);
    (async () => {
      const { default: RoutesMap, validateRoute } = await import('Utils/BreadcrumbRoutesMap');

      const matchingRoute = Object.keys(RoutesMap).find((route) => validateRoute(pathname, route));

      if (matchingRoute) {
        const promiseFunc = RoutesMap[matchingRoute];
        if (promiseFunc) {
          const exportContent = await promiseFunc();

          if (exportContent.breadcrumbData) {
            setBreadcrumbContent(
              exportContent.breadcrumbData.map((item) => ({
                name:
                  typeof item === 'string'
                    ? item
                    : typeof item === 'function'
                    ? item(pathname)
                    : typeof item.name === 'function'
                    ? item.name(pathname)
                    : item.name,
                path:
                  typeof item === 'string' || typeof item === 'function'
                    ? undefined
                    : typeof item.path === 'function'
                    ? item.path(pathname)
                    : item.path
              }))
            );
          }
        }
      }
    })();
  }, [pathname]);

  const reset = useCallback(() => {
    setCustomLeftHeaderItem(null);
    setCustomRightHeaderItem(null);
  }, [setCustomLeftHeaderItem, setCustomRightHeaderItem]);

  return (
    <UiContext.Provider
      value={{
        sideMenuIsOpen,
        toggleSideMenuIsOpen,
        customLeftHeaderItem,
        customRightHeaderItem,
        reset,
        setCustomLeftHeaderItem,
        setCustomRightHeaderItem,
        showModal,
        setShowModal,
        modalChildren,
        setModalChildren,
        showDrawer,
        setShowDrawer,
        drawerChildren,
        setDrawerChildren,
        toast,
        breadcrumbContent
      }}
    >
      <ToastContainer theme="colored" />
      {children}
    </UiContext.Provider>
  );
};

export default UiContext;
