import { SideContentProvider } from './SideContentContext';
import { StoreContextProvider } from './StoreContext';
import { UiContextProvider } from './UiContext';
import LogError from 'Utils/LogError';
import { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: LogError
    }
  }
});

const ContextProvider: React.FC<PropsWithChildren> = () => (
  <UiContextProvider>
    <QueryClientProvider client={queryClient}>
      <StoreContextProvider>
        <SideContentProvider>
          <Outlet />
        </SideContentProvider>
      </StoreContextProvider>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  </UiContextProvider>
);

export default ContextProvider;
