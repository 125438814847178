const setColor = {
  success: 'rgb(11, 124, 47)',
  warning: 'rgb(255, 188, 44)',
  info: 'rgb(14, 85, 164)',
  danger: 'rgb(189, 6, 11)',
  aqua: 'rgb(34, 177, 169)',
  brand_mn: 'rgb(255, 128, 36)',
  brand_uh: 'rgb(0, 136, 165)',
  brand_mn_uh: 'rgb(130, 24, 92)',
  secondary: 'rgb(229, 229, 229)',
  link: 'rgb(0, 112, 171)'
} as const;

type Props = {
  theme?: keyof typeof setColor;
};

const IconAtendimento: React.FC<Props> = (props) => {
  const { theme } = props;
  const fillColor = theme ? setColor[theme] : setColor.aqua;
  return (
    <svg
      id="icon-atendimento"
      xmlns="http://www.w3.org/2000/svg"
      width="24.036"
      height="24.036"
      viewBox="0 0 24.036 24.036"
    >
      <g id="Grupo_18925" data-name="Grupo 18925">
        <g id="Rectangle_7_copy" data-name="Rectangle 7 copy">
          <rect
            id="Retângulo_1958"
            data-name="Retângulo 1958"
            width="24.036"
            height="24.036"
            fill="none"
          />
        </g>
        <line
          id="Linha_1022"
          data-name="Linha 1022"
          x2="23.411"
          y2="23.411"
          transform="translate(0.416 0.417)"
          fill="none"
        />
        <line
          id="Linha_1023"
          data-name="Linha 1023"
          x1="23.411"
          y2="23.411"
          transform="translate(0.312 0.313)"
          fill="none"
        />
        <g id="Grupo_18720" data-name="Grupo 18720" transform="translate(0.311 0.18)">
          <g
            id="Rectangle_7_copy-2"
            data-name="Rectangle 7 copy"
            transform="translate(0.082 0.185)"
          >
            <rect
              id="Retângulo_1958-2"
              data-name="Retângulo 1958"
              width="23.183"
              height="23.183"
              fill="none"
            />
          </g>
          <line
            id="Linha_17"
            data-name="Linha 17"
            x2="23.411"
            y2="23.411"
            transform="translate(0.105 0.105)"
            fill="none"
          />
          <line id="Linha_18" data-name="Linha 18" x1="23.411" y2="23.411" fill="none" />
        </g>
        <path
          id="Caminho_65701"
          data-name="Caminho 65701"
          d="M503,448.1Z"
          transform="translate(-493.59 -431.015)"
          fill="#34456b"
        />
        <path
          id="Caminho_65702"
          data-name="Caminho 65702"
          d="M470.6,379.4Z"
          transform="translate(-465.361 -371.16)"
          fill="#34456b"
        />
        <path
          id="Caminho_65703"
          data-name="Caminho 65703"
          d="M499.5,413.8Z"
          transform="translate(-490.541 -401.132)"
          fill="#34456b"
        />
        <path
          id="Caminho_65704"
          data-name="Caminho 65704"
          d="M497.9,441.8Z"
          transform="translate(-489.146 -425.526)"
          fill="#34456b"
        />
        <path
          id="Caminho_65705"
          data-name="Caminho 65705"
          d="M528.8,445.9Z"
          transform="translate(-516.068 -429.099)"
          fill="#34456b"
        />
        <g id="ico-chamado" transform="translate(0.323 0.874)">
          <g id="ico-engine-1">
            <path
              id="Caminho_65519"
              data-name="Caminho 65519"
              d="M259.8,385.984a3.051,3.051,0,1,1-3.053-3.051,3.051,3.051,0,0,1,3.053,3.051Zm-2.222-5.005h-1.574l-.508,1.041v.368a3.8,3.8,0,0,0-1.2.685l-.361-.2-1.156-.065-.768,1.375.661.951.323.18a3.869,3.869,0,0,0-.06.668,3.787,3.787,0,0,0,.058.651l-.35.207-.637.969.8,1.354,1.155-.094.314-.186a3.814,3.814,0,0,0,1.144.672v.4l.517,1.036,1.574-.013.5-1.046v-.354a3.807,3.807,0,0,0,1.161-.657l.339.192,1.156.075.78-1.369-.653-.962-.306-.174a3.784,3.784,0,0,0,.012-1.336l.338-.2.645-.962-.792-1.362-1.155.084-.311.181a3.822,3.822,0,0,0-1.143-.679v-.4l-.508-1.041Z"
              transform="translate(-238.089 -375.118)"
              fill={`${fillColor}`}
            />
          </g>
          <g id="ico-engine-2">
            <path
              id="Caminho_65521"
              data-name="Caminho 65521"
              d="M222.356,345.355a2.24,2.24,0,1,1-2.24,2.24,2.24,2.24,0,0,1,2.24-2.24Zm-3.478,3.581.589.994.847-.069.231-.136a2.791,2.791,0,0,0,.841.493v.292l.38.761,1.156-.01.361-.767v-.261a2.762,2.762,0,0,0,.852-.481l.249.142.848.055.572-1-.481-.7-.225-.127a2.785,2.785,0,0,0,.008-.981l.248-.145.474-.705-.582-1-.848.061-.228.133a2.8,2.8,0,0,0-.841-.5v-.292l-.373-.764H221.8l-.373.764v.269a2.789,2.789,0,0,0-.883.5l-.261-.146-.85-.047-.562,1.01.481.7.24.133a2.765,2.765,0,0,0,0,.967l-.256.153-.468.71Z"
              transform="translate(-209.806 -343.926)"
              fill={`${fillColor}`}
            />
          </g>
          <path
            id="Caminho_65155"
            data-name="Caminho 65155"
            d="M458.222,438.9h3.151a.327.327,0,0,0,.327-.327v-6.861a.327.327,0,0,0-.327-.327h-3.151a.327.327,0,0,0-.327.327v6.861A.327.327,0,0,0,458.222,438.9Z"
            transform="translate(-457.463 -419.073)"
            fill="#7b7b7b"
          />
          <path
            id="Caminho_65157"
            data-name="Caminho 65157"
            d="M579.245,404.682a2.2,2.2,0,1,1,.648-1.562,2.2,2.2,0,0,1-.648,1.562Zm-1.562-4.747a3.191,3.191,0,1,0,2.253.935A3.191,3.191,0,0,0,577.682,399.935Z"
            transform="translate(-559.104 -392.179)"
            fill={`${fillColor}`}
          />
          <path
            id="Caminho_65159"
            data-name="Caminho 65159"
            d="M535.571,352.968a1.442,1.442,0,1,1-.425,1.024A1.442,1.442,0,0,1,535.571,352.968Zm1.024,3.549a2.524,2.524,0,1,0-1.784-.742,2.524,2.524,0,0,0,1.784.742Z"
            transform="translate(-524.102 -350.365)"
            fill={`${fillColor}`}
          />
          <path
            id="Caminho_65160"
            data-name="Caminho 65160"
            d="M582.557,467.217Z"
            transform="translate(-565.56 -450.147)"
            fill="#34456b"
          />
          <rect
            id="Retângulo_6318"
            data-name="Retângulo 6318"
            height="0.002"
            transform="matrix(0.555, -0.832, 0.832, 0.555, 15.391, 19.044)"
            fill="#34456b"
          />
          <path
            id="Caminho_65161"
            data-name="Caminho 65161"
            d="M455.408,431.041h2.962v6.672h-2.962Zm20.369,5.831-5.507,1.618a8.894,8.894,0,0,1-2.5.361c-.168,0-.336,0-.5-.016a.422.422,0,0,0-.048.841q.276.016.554.016a9.722,9.722,0,0,0,2.74-.394l5.507-1.617a2.175,2.175,0,0,0,1.562-2.085v-.066a.923.923,0,0,0-.924-.921.99.99,0,0,0-.12.01h0l-4.885.579-.065-.077-.042-.048h0l-.278-.324h0a4.079,4.079,0,0,0-.474-.468h0a11.069,11.069,0,0,0-5.986-2.524H464.8l-5.573-.464v-.341a.748.748,0,0,0-.749-.749h-3.151a.749.749,0,0,0-.749.749v6.861a.749.749,0,0,0,.749.749h3.151a.749.749,0,0,0,.749-.749v-1.5l.9.05a2.372,2.372,0,0,1,.568.208,1.625,1.625,0,0,1,.281.2c.13.113.262.26.438.439a5.655,5.655,0,0,0,.69.612c.171.127.36.26.564.392h0a9.353,9.353,0,0,0,1.032.573.422.422,0,1,0,.361-.761,8.322,8.322,0,0,1-.939-.523q-.283-.18-.514-.36a4.835,4.835,0,0,1-.537-.47c-.109-.112-.209-.219-.315-.327a2.869,2.869,0,0,0-.573-.47,3.089,3.089,0,0,0-.887-.346.435.435,0,0,0-.076-.011l-.993-.056v-3.328l5.5.458a10.207,10.207,0,0,1,5.528,2.331,3.2,3.2,0,0,1,.376.371h0c.091.106.186.215.274.318h0l.041.048h0c.052.06.1.12.144.172h0a.345.345,0,0,0,.026.032h0l.028.035h0a.228.228,0,0,1,.053.141v.029a.5.5,0,0,1-.06.162.467.467,0,0,1-.221.194h0a.407.407,0,0,1-.048.018h0l-.024.006-.037.007h-.011a.367.367,0,0,1-.05,0h-.027a.465.465,0,0,1-.154-.026h-.006l-.053-.023h0a.456.456,0,0,1-.049-.029h0l-.9-.6a.422.422,0,1,0-.469.7l.9.6a1.437,1.437,0,0,0,.137.08h.01c.044.022.09.041.137.059l.024.008h.008a1.32,1.32,0,0,0,.416.07h.07a.476.476,0,0,0,.12-.011h.048l.1-.023h.022l.03-.008a1.4,1.4,0,0,0,.147-.053h0a1.323,1.323,0,0,0,.608-.536h0a1.321,1.321,0,0,0,.154-.413h0v-.024a.922.922,0,0,0,.013-.093h0v-.087c0-.028-.007-.03-.008-.048l4.6-.546h.028a.085.085,0,0,1,.056.024.1.1,0,0,1,.017.026.089.089,0,0,1,.007.031v.066a1.329,1.329,0,0,1-.954,1.275Z"
            transform="translate(-454.575 -418.043)"
            fill="#434343"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconAtendimento;
