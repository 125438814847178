import RedirectLogin from 'Utils/RedirectLogin';
import GetCookie from 'Utils/GetCookie';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const RequireAuth = () => {
  useEffect(() => {
    if (GetCookie('DNA') === null) {
      return RedirectLogin();
    }
  }, []);

  return <Outlet />;
};

export default RequireAuth;
