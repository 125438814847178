import S from './UpdateAddress.component.style.module.scss';
import logoUol from '../../../Assets/Images/PNG/logo-virtuol.png';
import logoUpdateFrete from '../../../Assets/Images/SVG/ilustra.svg';
import { Modal } from 'uol-host-react-ui';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

const UpdateAddress: React.FC<Props> = ({}) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(true);

  const navigate = useNavigate();
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleRedirectFreight = () => {
    setIsOpen(false);
    return navigate('/configuracoes/frete');
  };

  return (
    <Modal onClose={handleClose} show={isOpen}>
      <header className={S.headerStyle}>
        <img src={logoUol} alt="logoUol" />
      </header>
      <main>
        <section className={S.sectionWarning}>
          <img src={logoUpdateFrete} alt="logo do frete" width="133" height="122" />
          <h2 className={S.descriptionWarning}>
            <strong className={S.warningImportant}>AVISO IMPORTANTE:</strong> <br /> Evite problemas
            atualizando o seu endereço.
          </h2>
        </section>
        <section className={S.warningAddress}>
          <p className={S.helloShopkeeper}>
            Olá lojista! <br />
            <strong className={S.descriptionUpdateAddress}>
              Mantenha seu endereço atualizado para cálculos precisos de frete e geração correta de
              etiquetas via Envio Fácil.
            </strong>
          </p>

          <p className={S.descriptionManagerFreight}>
            Atualize seu endereço agora mesmo no menu Gestão de frete para evitar inconvenientes.
          </p>

          <button onClick={handleRedirectFreight} className={S.buttonUpdateAddressNow}>
            Atualizar o endereço agora mesmo!
          </button>
        </section>
      </main>
      <footer className={S.footerThanks}>
        <p className={S.descriptionFooterThanks}>
          Agradecemos sua atenção e estamos aqui para melhorar sua experiência! <br />
          <span className={S.signedVirtualStoreTeam}>Equipe Loja VirtUOL</span>
        </p>
      </footer>
    </Modal>
  );
};

export default UpdateAddress;
